$red: #f7adad;
$green: #b7efb7;
$grey: #eee;
$dark-grey: #343434;

p, a, h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

body {

  #root {
    .container {
      display: flex;
      flex-direction: column;
      max-width: 800px;
      width: 100%;
      margin: 0 auto;
    }

    .selectors {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &--dashboard {
        flex-direction: row;
      }

      .MuiFormControl-root {
        width: 100%;
      }

      > svg {
        display: none;
      }

      @media only screen and (min-width: 768px) {
        flex-direction: row;

        > svg {
          display: block;
        }

        .MuiFormControl-root {
          max-width: 200px;
        }
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      padding: 1rem;
      border: 1px solid $grey;
      border-radius: 8px;

      .check-items {
        &-label {
          margin-bottom: 1.25rem;

          .MuiFormControlLabel-label {
            font-size: 1.25rem;
          }
        }

        &-group {
          padding: 1rem;
        }
      }
    }

    .select {
      &-label {
        &-item {
          text-transform: capitalize;
        }
      }
    }

    #alert-dialog-description {
      margin-bottom: 1rem;
    }

    .navbar {
      border-bottom: 1px solid $grey;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: .5rem 0;

      &--top,
      &--bottom {
        display: flex;
        justify-content: space-between;
        margin-bottom: .5rem;
        width: 100%;
      }

      &--left,
      &--right {
        align-items: center;
        display: flex;
      }

      ul {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          align-items: center;
          display: flex;

          &:after {
            content: "";
            border-right: 1px solid #000;
            display: block;
            height: 1rem;
            margin: 0 0.5rem;
          }

          &:last-of-type {
            &:after {
              display: none;
            }
          }

          a {
            color: rgba(0, 0, 0, 0.87);
            text-decoration: none;
          }
        }
      }

      .sign-in,
      .sign-out {
        background: none;
        border: none;
        text-transform: uppercase;
      }
    }
  }

  .item {
    &-page {

      &--accordion {
        margin-bottom: 1rem;

        .color-true {
          background-color: $green;
        }

        .color-false {
          background-color: $red;
        }
      }

      &--result {
        border-radius: 4px;
        margin-bottom: 1rem;
        padding: .25rem 1rem;
      }
    }

    &--archive {
      ul {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          background-color: $grey;
          border-radius: 4px;
          margin-bottom: 1rem;
          margin-right: 1rem;
          text-align: center;
          width: calc(33% - 1rem);

          &:nth-of-type(3n) {
            margin-right: 0;
          }

          &:hover {
            background-color: darken($grey, 10%);
          }
        }

        a {
          color: #000;
          display: block;
          font-size: .875rem;
          padding: 1rem;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }
  }

  .tools {
  }

  .MuiDataGrid {
    &-root {
      // border: none;

      a {
        color: #525252;
      }

      .fail {
        background-color: $red;
      }

      .pass {
        background-color: $green;
      }

      .MuiDataGrid {

        &-row {

          &.Mui-selected {
            background-color: #a0a0ff;
            color: #ffffff;

            &:hover {
              background-color: #a0a0ff;
            }

            a {
              color: #ffffff;
            }
          }

          &:hover {
            background-color: #a0a0ff;
          }
        }

        &-cell {
          border-bottom: 1px solid $dark-grey;
        }
      }
    }
  }

  .MuiCollapse {
    &-wrapper {
      margin-bottom: 1rem;
    }
  }

  .MuiButton-root {
    padding: 15px;
  }
}

.MuiTextField-root.submit-dialog-note {
  border: 1px solid #eee;
  border-radius: 8px;
  width: 100%;
}
